.truncate-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: inherit;
}

.truncate-2 {
  @apply truncate-wrap;
  -webkit-line-clamp: 2;
}

.truncate-3 {
  @apply truncate-wrap;
  -webkit-line-clamp: 3;
}

.truncate-4 {
  @apply truncate-wrap;
  -webkit-line-clamp: 4;
}

.truncate-5 {
  @apply truncate-wrap;
  -webkit-line-clamp: 5;
}

.truncate-6 {
  @apply truncate-wrap;
  -webkit-line-clamp: 6;
}

@media (max-width: 850px) {
  .no-bg-on-mobile {
    background-image: none !important;
  }
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
