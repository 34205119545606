.seo-content {
  font-family: Arial;
}

.seo-content p {
  @apply leading-6;
}

.seo-content a {
  @apply underline font-bold;
  color: #1a73e8;
}

.seo-content a:hover {
  @apply text-link-dark underline;
}

.seo-content h3 {
  @apply font-bold mb-6 text-2xl;
}

.seo-content h2 {
  @apply font-bold mb-6 text-2xl;
}

.seo-content h4 {
  @apply font-semibold mb-6 mt-12 text-4xl;
}

.seo-content h5 {
  @apply font-bold mb-6 mt-12 text-2xl;
}

.seo-content h6 {
  @apply font-bold mb-6 mt-12 text-2xl;
}

.seo-content img {
  @apply w-full my-4;
}

.seo-content iframe {
  @apply w-full my-16 rounded;
  height: 600px;
}

.seo-content ul,
.seo-content ol {
  list-style: disc;
  margin-left: 20px;
}

.seo-content ol {
  list-style: decimal;
}
