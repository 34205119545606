.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 36px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: #258ada;
  color: white;
  border-radius: 8px;
}

.react-calendar__navigation button {
  font-weight: 600;
  min-width: 36px;
  background: none;
}

/* .react-calendar__navigation button:disabled {
} */

.react-calendar__navigation button {
  cursor: default;
}

.react-calendar__navigation__arrow {
  display: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  padding: 4px 0;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

/* .react-calendar__month-view__days__day--weekend {
  
} */

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #dc2626;
  color: #dc2626;
  display: none;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 8px;
  border: 1px solid white !important;
  font-weight: 600;
  transition: all 0.3s ease;
}

.react-calendar__tile--available:hover {
  background-color: #e6e6e6;
}

.react-calendar__tile--past-dates {
  opacity: 0.2;
}

.react-calendar__tile--available {
  background-color: white;
  color: #3a3a3a;
}

.react-calendar__tile--not-available {
  background-color: #dc2626;
  color: white;
}

.react-calendar__tile--booking {
  background-color: rgb(21, 128, 61);
  color: white;
}

.react-calendar__tile--blocked-with-booking {
  background: linear-gradient(135deg, rgb(21, 128, 61) 50%, #dc2626 50%);
  color: white;
}

.react-calendar__tile--blocked-by-settings {
  background-color: white;
  color: #3a3a3a;
  opacity: 0.2;
}

.react-calendar__tile:disabled {
  cursor: not-allowed;
}

/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
} */

/* .react-calendar__tile--now {
  background: deepskyblue;
  color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
} */

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

/* .react-calendar__tile--active {
  background: #DC2626;
  color: white;
} */

/* .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #DC2626;
} */

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
