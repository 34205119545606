.blog-content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.blog-content span,
.blog-content p,
.blog-content div {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 20px !important;
  line-height: 2rem;
}

.blog-content p {
  @apply mb-6 leading-8;
}

.blog-content a {
  @apply underline font-bold break-words;
  color: #1a73e8;
}

.blog-content a:hover {
  @apply text-link-dark underline;
}

.blog-content h4,
.blog-content h5,
.blog-content h6,
.blog-content h2 span,
.blog-content h3 span,
.blog-content h4 span,
.blog-content h5 span,
.blog-content h6 span {
  font-size: revert !important;
  font-weight: revert;
}

.blog-content h2 {
  @apply text-42px mt-50px mb-20px;
}
.blog-content h3 {
  @apply text-32px mt-50px mb-20px;
}

.blog-content h4,
.blog-content h5,
.blog-content h6 {
  @apply mb-6 mt-12;
}

.blog-content img {
  @apply w-full my-4;
}

.blog-content iframe {
  @apply w-full my-16 rounded;
  height: 600px;
}

.blog-content ul,
.blog-content ol {
  list-style: disc;
  margin-left: 20px;
  font-size: 20px !important;
  line-height: 2rem;
}

.blog-content ol {
  list-style: decimal;
  font-size: 20px !important;
  line-height: 2rem;
}

.blog-content li {
  margin-bottom: 10px;
  font-size: 20px !important;
  line-height: 2rem;
}

.blog-content figure {
  width: 100% !important;
}
